import { Html, PointerLockControls, Preload } from "@react-three/drei";
import { Player } from "./Player";
import { Physics } from "@react-three/rapier";
import { Ground } from "./Ground";
import Office from "./Office";
import React from "react";
import useBearStore from "../../hooks/state";
import MusicPlayer from "./MusicPlayer";

const FPS = () => {
  const bears = useBearStore((state) => state.showMain);

  return (
    <>
      <Physics gravity={[0, -30, 0]} colliders="cuboid">
        <Preload all />
        <Ground />
        {!bears && <MusicPlayer />}
        <Player />
        <Office />
        <PointerLockControls enabled={!bears} />
      </Physics>
    </>
  );
};

export default FPS;
