import React, { useEffect, useState } from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { Html, useTexture, Text } from "@react-three/drei";
import { Popconfirm } from "antd";
import useBearStore from "../../hooks/state";
import VideoScene from "../VideoScene/VideoScene";
import Ship from "./Ship";

// function BearCounter() {
//   const bears = useBearStore((state) => state.showMain);
//   return <h1>{bears} around here ...</h1>;
// }

function Controls() {
  const increasePopulation = useBearStore((state) => state.toggleMain);
  return <button onClick={increasePopulation}>one up</button>;
}

const store = [
  {
    name: "outside",
    color: "lightpink",
    position: [18.1, -3, -18],
    icon: "./big-logo.png",
    url: `./images/${Math.floor(Math.random() * (17 - 1 + 1) + 1)}.jpeg`,
    link: 1,
  },
  {
    name: "inside",
    color: "lightblue",
    position: [15, 0, 0],
    url: "./images/1.jpeg",
    link: 0,
  },
  // ...
];

function Portals() {
  const [which, set] = useState(0);
  const { link, ...props } = store[which];
  const maps = useLoader(THREE.TextureLoader, store.map((entry) => entry.url)) // prettier-ignore
  return <Dome onClick={() => set(link)} {...props} texture={maps[which]} />;
}

function Dome({ name, position, texture, onClick, color, icon }) {
  const increasePopulation = useBearStore((state) => state.toggleMain);
  const bears = useBearStore((state) => state.showMain);
  const textureLogo = useTexture("./big-logo.png");

  return (
    <group scale={1} position={[0, 15, 0]}>
      <mesh>
        <sphereGeometry args={[500, 60, 40]} />
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
      </mesh>
      {/*<mesh position={position}>*/}
      {/*  <mesh position={[-10, 0, 15]} scale={0.2}>*/}
      {/*    <VideoScene />*/}
      {/*  </mesh>*/}
      {/*  /!*<mesh*!/*/}
      {/*  /!*  scale={[7, 8, 8]}*!/*/}
      {/*  /!*  position={[-6.1, 3.2, 0]}*!/*/}
      {/*  /!*  rotation={[0, -1.5, 0.03]}*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  <planeGeometry />*!/*/}
      {/*  /!*  <meshBasicMaterial map={textureLogo} toneMapped={false} />*!/*/}
      {/*  /!*</mesh>*!/*/}
      {/*  /!*<mesh position={[-10, 3, 0]} scale={100}>*!/*/}
      {/*  /!*  <Html center>*!/*/}
      {/*  /!*    <img width={150} src={"./big-logo.png"} />*!/*/}
      {/*  /!*  </Html>*!/*/}
      {/*  /!*</mesh>*!/*/}
      {/*  /!*<sphereGeometry args={[1.25, 32, 32]} />*!/*/}
      {/*  /!*<meshBasicMaterial color={color} />*!/*/}
      {/*</mesh>*/}
    </group>
  );
}

export default Portals;
