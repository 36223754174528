/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ship(props) {
  const { nodes, materials } = useGLTF("/office.gltf");
  return (
    <group {...props} dispose={null}>
      <group>
        <group position={[0, 0.09, 0]}>
          <mesh
            geometry={nodes.Cube003.geometry}
            material={materials.Material}
          />
          <mesh
            geometry={nodes.Cube003_1.geometry}
            material={materials.plate}
          />
          <mesh
            geometry={nodes.Cube003_2.geometry}
            material={materials.Light}
          />
        </group>
        <mesh
          geometry={nodes.ShipRaw003.geometry}
          material={materials.plate}
          position={[0, 0.09, 0]}
        />
        <group position={[0, 0.09, 0]}>
          <mesh
            geometry={nodes.Cube009.geometry}
            material={materials.Material}
          />
          <mesh
            geometry={nodes.Cube009_1.geometry}
            material={materials.plate}
          />
        </group>
        <mesh
          geometry={nodes.Cube002.geometry}
          material={materials.Windows}
          position={[0, 3.03, -1.51]}
          scale={[0.44, 0.33, 0.44]}
        />
        <mesh
          geometry={nodes.Cube001.geometry}
          material={materials.Material}
          position={[0, 3.03, -1.51]}
          scale={[0.44, 0.33, 0.44]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/office.gltf");

export default Ship;
