import { Html } from "@react-three/drei";
import React from "react";

const MusicPlayer = () => {
  return (
    <mesh name="Plane001" position={[9.5, 2.8, -2.5]} scale={0.5}>
      <Html transform occlude>
        <div className={"music-player"}>
          <iframe
            style={{ border: "0", zIndex: 0 }}
            src="https://open.spotify.com/embed/playlist/12fBYXc4OYC1mFlkjppu6u?utm_source=generator&theme=0"
            width="100%"
            height="152"
            allowFullScreen={false}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </Html>
    </mesh>
  );
};

export default MusicPlayer;
