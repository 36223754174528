function Nav() {
  return (
    <div className={"nav"}>
      <div className={"logo"}>
        <img src={"./logo.png"} alt={"logo"} />
      </div>
      {/*LOGO ABOUT SERVICES PARTNERS EXPERTISE*/}
      <div className={"pressEsc"}>Press "ESC" to show your cursor.</div>
    </div>
  );
}

export default Nav;
