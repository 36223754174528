import ReactGA from "react-ga4";

import "./App.css";

import Canvas3D from "./components/Canvas3D/Canvas3D";
import Nav from "./components/Nav/Nav";
import useBearStore from "./hooks/state";
import Main from "./components/Main/Main";
import { useEffect } from "react";

const TRACKING_ID = "G-82VWCRFHWS"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID, {});

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home",
    });
  }, []);

  const bears = useBearStore((state) => state.showMain);

  return (
    <div className="App">
      <div className={"wrapper"}>
        <Nav />
        {bears && <Main />}
      </div>
      <Canvas3D />
    </div>
  );
}

export default App;
