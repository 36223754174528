import React, { useRef } from "react";
import { useGLTF, Wireframe } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/office.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={nodes.Cube.material}
        position={[0.48, 0, 0]}
      >
        {/*<Wireframe fillOpacity={0.05} fill={"#000000"} />*/}
      </mesh>
      {/*<Wireframe*/}
      {/*  geometry={nodes.Cube.geometry} // Will create the wireframe based on input geometry.*/}
      {/*  simplify={false} // Remove some edges from wireframes*/}
      {/*  fill={"#00ff00"} // Color of the inside of the wireframe*/}
      {/*  fillMix={0} // Mix between the base color and the Wireframe 'fill'. 0 = base; 1 = wireframe*/}
      {/*  fillOpacity={0.25} // Opacity of the inner fill*/}
      {/*  stroke={"#ff0000"} // Color of the stroke*/}
      {/*  strokeOpacity={1} // Opacity of the stroke*/}
      {/*  thickness={0.05} // Thinkness of the lines*/}
      {/*  colorBackfaces={false} // Whether to draw lines that are facing away from the camera*/}
      {/*  backfaceStroke={"#0000ff"} // Color of the lines that are facing away from the camera*/}
      {/*  dashInvert={true} // Invert the dashes*/}
      {/*  dash={false} // Whether to draw lines as dashes*/}
      {/*  dashRepeats={4} // Number of dashes in one seqment*/}
      {/*  dashLength={0.5} // Length of each dash*/}
      {/*  squeeze={false} // Narrow the centers of each line segment*/}
      {/*  squeezeMin={0.2} // Smallest width to squueze to*/}
      {/*  squeezeMax={1} // Largest width to squeeze from*/}
      {/*/>*/}
    </group>
  );
}

useGLTF.preload("/office.gltf");

export default Model;
