import "./styles.css";

import * as THREE from "three";
import React, { Suspense, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Plane,
  PointerLockControls,
  useAspect,
  useTexture,
} from "@react-three/drei";
import {
  EffectComposer,
  DepthOfField,
  Vignette,
  TiltShift2,
} from "@react-three/postprocessing";
// import { MaskFunction } from "postprocessing";
import Fireflies from "./components/Fireflies";
import bgUrl from "./resources/bg.png";
import starsUrl from "./resources/stars.png";
import groundUrl from "./resources/ground.png";
import bearUrl from "./resources/hero.png";
import leaves1Url from "./resources/leaves1.png";
import "./materials/layerMaterial";
import VideoScene from "../VideoScene/VideoScene";

function Scene({ dof }) {
  const scaleN = useAspect(1600, 1000, 1.05);
  const scaleW = useAspect(300, 300, 1.05);
  const textures = useTexture([
    bgUrl,
    starsUrl,
    groundUrl,
    bearUrl,
    leaves1Url,
    starsUrl,
  ]);
  const group = useRef();
  const layersRef = useRef([]);
  const [movement] = useState(() => new THREE.Vector3());
  const [temp] = useState(() => new THREE.Vector3());
  const layers = [
    { texture: textures[0], z: 29, factor: 0.005, scale: scaleW },
    { texture: textures[1], z: 10, factor: 0.005, scale: scaleW },
    { texture: textures[2], z: 20, scale: scaleW },
    { texture: textures[3], z: 30, scaleFactor: 1, scale: scaleN },
    {
      texture: textures[4],
      factor: 0.03,
      scaleFactor: 1,
      z: 40,
      wiggle: 0.6,
      scale: scaleW,
    },
    {
      texture: textures[5],
      factor: 0.04,
      scaleFactor: 1.3,
      z: 49,
      wiggle: 1,
      scale: scaleW,
    },
  ];

  useFrame((state, delta) => {
    movement.lerp(temp.set(state.mouse.x, state.mouse.y * 0.2, 0), 0.2);
    group.current.position.x = THREE.MathUtils.lerp(
      group.current.position.x,
      state.mouse.x * 20,
      0.2
    );
    group.current.rotation.x = THREE.MathUtils.lerp(
      group.current.rotation.x,
      state.mouse.y / 10,
      0.2
    );
    group.current.rotation.y = THREE.MathUtils.lerp(
      group.current.rotation.y,
      -state.mouse.x / 2,
      0.2
    );
    layersRef.current[4].uniforms.time.value =
      layersRef.current[5].uniforms.time.value += delta;
  }, 1);

  return (
    <group ref={group}>
      <Fireflies count={20} radius={80} colors={["white"]} />
      {layers.map(
        (
          {
            scale,
            texture,
            ref,
            factor = 0,
            scaleFactor = 1,
            wiggle = 0,
            z,
            y = 0,
            x = 0,
          },
          i
        ) => (
          <Plane
            scale={scale}
            args={[1, 1, wiggle ? 10 : 1, wiggle ? 10 : 1]}
            position-z={z}
            position-y={y}
            position-x={x}
            key={i}
            ref={ref}
          >
            <layerMaterial
              movement={movement}
              textr={texture}
              factor={factor}
              ref={(el) => (layersRef.current[i] = el)}
              wiggle={wiggle}
              scale={scaleFactor}
            />
          </Plane>
        )
      )}
    </group>
  );
}

const Effects = () => (
  <EffectComposer disableNormalPass multisampling={0}>
    <DepthOfField
      target={[0, 0, 30]}
      bokehScale={8}
      focalLength={0.1}
      width={1024}
    />
    {/*<TiltShift2 blur={0.25} samples={10} />*/}
    {/*<Vignette />*/}
  </EffectComposer>
);

export const ZustandScene = () => {
  // console.log(window.innerWidth);
  return (
    <Canvas
      // sRGB
      // powerPreference={"low-power"}
      gl={{ antialias: false, alpha: false }}
      orthographic
      camera={{ zoom: 5, position: [0, 0, 200], far: 300, near: 50 }}
    >
      <Scene />
      <Effects />
      {/*<Suspense fallback={null}>*/}
      {/*  <VideoScene />*/}
      {/*</Suspense>*/}
    </Canvas>
  );
};

export default ZustandScene;
