import { create } from "zustand";

const useBearStore = create((set) => ({
  // bears: 0,
  showMain: true,
  toggleMain: () => set((state) => ({ showMain: !state.showMain })),
  // increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  // removeAllBears: () => set({ bears: 0 }),
}));

export default useBearStore;
