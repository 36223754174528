import ZustandScene from "../Zustand/ZustandScene";
import useBearStore from "../../hooks/state";

function Main() {
  const increasePopulation = useBearStore((state) => state.toggleMain);

  return (
    <div className={"main-content"}>
      <div
        className={"main-content-close"}
        onClick={increasePopulation}
        id={"main-content-close"}
        // onTouchStart={increasePopulation}
      >
        x
      </div>
      <h1>
        Looking for <span style={{ fontSize: "2rem" }}>TECH</span>
        <br />
        <span style={{ fontSize: "5rem" }}>SOLUTIONS</span>
        <br />
        to <span style={{ fontSize: "3rem" }}>EVOLVE?</span>
      </h1>
      <div className={"button-container"} onClick={increasePopulation}>
        <button
          onClick={() => {
            /* eslint-disable-next-line no-undef */
            Chatra("openChat", true);
          }}
        >
          Talk to us
        </button>
      </div>
      <ZustandScene />
    </div>
  );
}

export default Main;
