import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";

export function Ground(props) {
  const texture = useTexture("./assets/images/grass.png");
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  return (
    <RigidBody {...props} type="fixed" colliders={false} scale={0.01}>
      <CuboidCollider args={[10000, 0, 10000]} position={[0, -4, 0]} />
    </RigidBody>
  );
}
