import React, { useRef } from "react";
import { useGLTF, Wireframe } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import VideoScene from "../VideoScene/VideoScene";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useLoader(
    GLTFLoader,
    "/new_office3.glb"
  );
  // const { actions } = useAnimations(animations, group);
  return (
    <>
      <RigidBody {...props}>
        <group name="Scene" dispose={null}>
          <mesh
            name="floor"
            geometry={nodes.floor.geometry}
            material={materials.plate}
            position={[6.113, -0.143, -5.413]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={1.436}
          >
            <Wireframe
              stroke={"#b7a805"} // Color of the stroke
            />
          </mesh>
          <mesh
            name="table"
            geometry={nodes.table.geometry}
            material={materials.MetalDark}
            position={[-3.314, 0.645, -3.226]}
          />
          <mesh
            name="chair"
            geometry={nodes.chair.geometry}
            material={materials.MetalDark}
            position={[-3.314, 0.645, -3.226]}
          />
          <mesh
            name="keyboard"
            geometry={nodes.keyboard.geometry}
            material={materials.Box}
            position={[0.198, 2.306, -2.065]}
            scale={[0.486, 0.035, 0.266]}
          />
          <mesh
            name="Cube003"
            geometry={nodes.Cube003.geometry}
            material={materials.Black}
            position={[-2.757, 1.558, -1.362]}
            scale={[0.78, 0.902, 2.176]}
          />
          <mesh
            name="Cube004"
            geometry={nodes.Cube004.geometry}
            material={materials.MetalDark}
            position={[3.534, 3.101, -3.159]}
          />
          <mesh
            name="mouse"
            geometry={nodes.mouse.geometry}
            material={materials.Box}
            position={[1.256, 2.329, -1.936]}
            scale={[0.093, 0.039, 0.133]}
          />
          <mesh
            name="table_legs"
            geometry={nodes.table_legs.geometry}
            material={materials.MetalDark}
            position={[-3.314, 0.645, -3.226]}
          />
          <mesh
            name="Cube008"
            geometry={nodes.Cube008.geometry}
            material={materials.Black}
            position={[0.253, 4.528, -3.492]}
            rotation={[0, -1.571, 0]}
            scale={[0.122, 1, 1.55]}
          />
          <mesh
            name="robot"
            geometry={nodes.robot.geometry}
            material={materials.Metal}
            position={[-2.77, 1.586, 2.85]}
            scale={[0.792, 0.917, 2.211]}
          />
          <mesh
            name="robot-base"
            geometry={nodes["robot-base"].geometry}
            material={materials.MetalDark}
            position={[-4.466, 1.672, 2.855]}
          />
          <mesh
            name="Cube013"
            geometry={nodes.Cube013.geometry}
            material={materials.Metal}
            position={[7.685, 1.548, -2.773]}
            rotation={[0, -1.571, 0]}
            scale={[0.78, 0.902, 2.176]}
          />
          <mesh
            name="box"
            geometry={nodes.box.geometry}
            material={materials.Box}
            position={[3.494, 4.558, -2.742]}
            scale={[0.6, 0.458, 0.607]}
          />
          <mesh
            name="box-mini"
            geometry={nodes["box-mini"].geometry}
            material={materials.Box}
            position={[3.846, 3.101, -2.94]}
            rotation={[0, -1.571, 0]}
            scale={[0.78, 0.902, 2.176]}
          />
          <group
            name="Cube001"
            position={[-0.544, 4.761, 0.045]}
            rotation={[Math.PI, -0.079, Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Top"
            geometry={nodes.Top.geometry}
            material={materials.Black}
            position={[2.803, 4.761, 0.014]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Wall"
            geometry={nodes.Wall.geometry}
            position={[2.803, 4.761, 0.014]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Wall001"
            geometry={nodes.Wall001.geometry}
            position={[2.803, 4.761, 0.014]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Wall002"
            geometry={nodes.Wall002.geometry}
            position={[2.803, 4.761, 0.014]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Wall003"
            geometry={nodes.Wall003.geometry}
            // material={materials.Windows}
            position={[2.803, 4.761, 0.014]}
            rotation={[-Math.PI, 0.001, -Math.PI]}
            scale={[11.018, 5.44, 11.018]}
          />
          <mesh
            name="Board"
            position={[0.3, 4.538, -3.5]}
            rotation={[0, 0, 0]}
            scale={0.18}
          >
            <VideoScene />
          </mesh>
        </group>
      </RigidBody>
      <group></group>
    </>
  );
}

useGLTF.preload("/new_office3.glb");

export default Model;
