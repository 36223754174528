import { useAspect, useTexture, useVideoTexture } from "@react-three/drei";

function VideoScene() {
  const size = useAspect(window.innerWidth, window.innerHeight);
  return (
    <mesh scale={size}>
      <planeGeometry />
      <VideoMaterial url="./assets/videos/evolution.mp4" />
    </mesh>
  );
}

export default VideoScene;

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url, { start: true });
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}
