import {
  Box,
  KeyboardControls,
  OrbitControls,
  Preload,
  Stage,
  Wireframe,
  Stats,
  PerformanceMonitor,
} from "@react-three/drei";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";

import Portals from "../DomePortalScene/DomePortal";
import * as THREE from "three";
import useBearStore from "../../hooks/state";
import Model from "./Model";
import Ship from "../DomePortalScene/Ship";
import FPS from "../FPS/FPS";
import {
  EffectComposer,
  Bloom,
  ToneMapping,
} from "@react-three/postprocessing";

function App() {
  const [dpr, setDpr] = useState(1);

  return (
    <div className="App">
      <div className="dot" />
      <div className={"canvas3d"}>
        <Suspense fallback={<span>loading...</span>}>
          <KeyboardControls
            map={[
              { name: "forward", keys: ["ArrowUp", "w", "W"] },
              { name: "backward", keys: ["ArrowDown", "s", "S"] },
              { name: "left", keys: ["ArrowLeft", "a", "A"] },
              { name: "right", keys: ["ArrowRight", "d", "D"] },
              { name: "jump", keys: ["Space"] },
            ]}
          >
            <Canvas
              // dpr={dpr}
              // shadowMap
              // sRGB
              gl={{ alpha: false }}
              // performance={{ min: 0.1 }}
              camera={{ position: [0, 0, 0.1] }}
            >
              <PerformanceMonitor
                onIncline={() => setDpr(1)}
                onDecline={() => setDpr(0.1)}
              >
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={null}>
                  <Preload all />
                  <Portals />
                  <FPS />
                </Suspense>
                <Stats showPanel={0} className="stats" />
              </PerformanceMonitor>
            </Canvas>
          </KeyboardControls>
        </Suspense>
      </div>
    </div>
  );
}

export default App;

const Boxe = () => {
  const mesh = useRef();
  const increasePopulation = useBearStore((state) => state.toggleMain);
  const texture = useLoader(THREE.TextureLoader, "./box-texture.png");
  // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <Box
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      ref={mesh}
      args={[5, 5, 5]}
      position={[-14, -18, -40]}
      rotation={[0.08, -0.05, -0.01]}
      onClick={increasePopulation}
      castShadow
      receiveShadow
    >
      <meshStandardMaterial />
      <meshBasicMaterial map={texture} color={hovered ? 0xffde78 : 0xe4b526} />
    </Box>
  );
};
